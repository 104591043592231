export enum CargoEnum {
  'SMALL' = 'fe81852f-e454-4030-bc89-43ae82cfd5b8',
  'MEDIUM' = 'a6723eba-ef02-4d4b-b56d-d64087b2a59b',
  'LARGE' = 'a392ee56-44e3-491b-b2e7-d45404455d08'
}

export enum StepEnum {
  'ORDER' = 'ORDER',
  'CONFIRM' = 'CONFIRM',
  'DONE' = 'DONE'
}


export type Location = {
  lat: string,
  lon: string,
  address: string
}

export type OrderData = {
  isHotOrder: boolean,
  name: string,
  phone: string,
  coords: Location[],
  categoryId: CargoEnum,
  driverHelp: boolean,
  dateStart: Date | string,
  totalDistance: number,
  insideDistance: number,
  outsideDistance: number,
  totalPrice: number,
}
